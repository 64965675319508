<template>
  <div class="admin-container">
    <tabs :activeTab="'addStore'" />

    <div class="merchant-add-store" v-loading="requestPending">
      <el-form
        :model="storeForm"
        status-icon
        :rules="rules"
        ref="storeForm"
        v-if="!storeAdded"
      >
        <h1>Add your store</h1>
        <br />

        <h3>Select your brand</h3>
        <br />
        <el-select v-model="selectedBrand">
          <el-option
            v-for="brand in brands"
            :key="brand.brand_name"
            :label="brand.brand_name"
            :value="brand.brand_name"
          ></el-option>
        </el-select>
        <br /><br />

        <div class="merchant-add-store__form-inner">
          <div>
            <el-form-item prop="name" label="Store name">
              <el-input type="text" v-model="storeForm.name"></el-input>
            </el-form-item>

            <el-form-item prop="storeUrl" label="Store web shop WWW">
              <el-input type="text" v-model="storeForm.storeUrl"></el-input>
            </el-form-item>

            <el-form-item
              prop="orderFulfillmentTime"
              label="Order fulfillment time"
            >
              <el-input
                type="text"
                v-model="storeForm.orderFulfillmentTime"
              ></el-input>
            </el-form-item>

            <h5>Open hours</h5>
            <br />

            <div class="merchant-add-store__hours">
              <el-form-item label="Mon." size="mini">
                <el-col :span="5">
                  <el-input
                    type="text"
                    v-model="storeForm.monFrom"
                    placeholder="07:00"
                  ></el-input>
                </el-col>
                <el-col :span="1">&nbsp;- </el-col>
                <el-col :span="5">
                  <el-input
                    type="text"
                    v-model="storeForm.monTo"
                    placeholder="21:00"
                  ></el-input>
                </el-col>
              </el-form-item>

              <el-form-item label="Tue." size="mini">
                <el-col :span="5">
                  <el-input
                    type="text"
                    v-model="storeForm.tueFrom"
                    placeholder="07:00"
                  ></el-input>
                </el-col>
                <el-col :span="1">&nbsp;- </el-col>
                <el-col :span="5">
                  <el-input
                    type="text"
                    v-model="storeForm.tueTo"
                    placeholder="21:00"
                  ></el-input>
                </el-col>
              </el-form-item>

              <el-form-item label="Wed." size="mini">
                <el-col :span="5">
                  <el-input
                    type="text"
                    v-model="storeForm.wedFrom"
                    placeholder="07:00"
                  ></el-input>
                </el-col>
                <el-col :span="1">&nbsp;- </el-col>
                <el-col :span="5">
                  <el-input
                    type="text"
                    v-model="storeForm.wedTo"
                    placeholder="21:00"
                  ></el-input>
                </el-col>
              </el-form-item>

              <el-form-item label="Thu." size="mini">
                <el-col :span="5">
                  <el-input
                    type="text"
                    v-model="storeForm.thuFrom"
                    placeholder="07:00"
                  ></el-input>
                </el-col>
                <el-col :span="1">&nbsp;- </el-col>
                <el-col :span="5">
                  <el-input
                    type="text"
                    v-model="storeForm.thuTo"
                    placeholder="21:00"
                  ></el-input>
                </el-col>
              </el-form-item>

              <el-form-item label="Fri." size="mini">
                <el-col :span="5">
                  <el-input
                    type="text"
                    v-model="storeForm.friFrom"
                    placeholder="07:00"
                  ></el-input>
                </el-col>
                <el-col :span="1">&nbsp;- </el-col>
                <el-col :span="5">
                  <el-input
                    type="text"
                    v-model="storeForm.friTo"
                    placeholder="21:00"
                  ></el-input>
                </el-col>
              </el-form-item>

              <el-form-item label="Sat." size="mini">
                <el-col :span="5">
                  <el-input
                    type="text"
                    v-model="storeForm.satFrom"
                    placeholder="07:00"
                  ></el-input>
                </el-col>
                <el-col :span="1">&nbsp;- </el-col>
                <el-col :span="5">
                  <el-input
                    type="text"
                    v-model="storeForm.satTo"
                    placeholder="21:00"
                  ></el-input>
                </el-col>
              </el-form-item>

              <el-form-item label="Sun." size="mini">
                <el-col :span="5">
                  <el-input
                    type="text"
                    v-model="storeForm.sunFrom"
                    placeholder="07:00"
                  ></el-input>
                </el-col>
                <el-col :span="1">&nbsp;- </el-col>
                <el-col :span="5">
                  <el-input
                    type="text"
                    v-model="storeForm.sunTo"
                    placeholder="21:00"
                  ></el-input>
                </el-col>
              </el-form-item>
            </div>
          </div>

          <div>
            <el-form-item prop="refundsPolicy" label="Refunds policy">
              <el-input
                type="textarea"
                v-model="storeForm.refundsPolicy"
              ></el-input>
            </el-form-item>

            <el-form-item prop="street" label="Street">
              <el-input type="text" v-model="storeForm.street"></el-input>
            </el-form-item>

            <el-form-item prop="city" label="City">
              <el-input type="text" v-model="storeForm.city"></el-input>
            </el-form-item>

            <el-form-item prop="province" label="Province">
              <el-input type="text" v-model="storeForm.province"></el-input>
            </el-form-item>

            <el-form-item prop="postalCode" label="Postal Code">
              <el-input type="text" v-model="storeForm.postalCode"></el-input>
            </el-form-item>

            <h3>Contact person info</h3>
            <br />

            <el-form-item prop="contactName" label="Name">
              <el-input type="text" v-model="storeForm.contactName"></el-input>
            </el-form-item>

            <el-form-item prop="contactPhone" label="Phone">
              <el-input type="text" v-model="storeForm.contactPhone"></el-input>
            </el-form-item>

            <el-form-item prop="contactEmail" label="Email">
              <el-input
                type="email"
                v-model="storeForm.contactEmail"
              ></el-input>
            </el-form-item>

            <el-form-item>
              <el-button
                class="merchant-add-store__submit"
                icon="el-icon-plus"
                type="primary"
                @click="submitForm('storeForm')"
                >Add store</el-button
              >
            </el-form-item>
          </div>
        </div>
      </el-form>

      <el-alert
        v-if="unexpectedError"
        title="Unexpected error"
        type="error"
        show-icon
        @close="unexpectedError = false"
      >
      </el-alert>

      <div v-if="storeAdded">
        <el-alert
          title="Your store has been added to the RoadRunner"
          type="success"
          description="The process of downloading store items to the RR database just started. It can take some time."
          show-icon
          @close="storeAdded = false"
        >
        </el-alert>
        <br />

        <el-button type="secondary" @click="resetTheForm()"
          >Add new store</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
  import Tabs from '../../components/MerchantAdmin/Tabs.vue'
  import storeAPI from '@/utils/api/storeAPI.js'

  export default {
    name: 'merchant-add-store',

    data() {
      const storeUrlInputValidator = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('Please fill the input'))
        } else {
          if (
            !value.match(
              /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/
            )
          ) {
            callback(new Error('Please put a valid URL'))
          }
          callback()
        }
      }
      const emailInputValidator = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('Please fill the input'))
        } else {
          if (!value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            callback(new Error('Please put a valid email address'))
          }
          callback()
        }
      }
      return {
        storeForm: {
          name: '',
          storeUrl: '',
          orderFulfillmentTime: '',
          refundsPolicy: '',
          contactName: '',
          contactPhone: '',
          contactEmail: '',
          street: '',
          city: '',
          province: '',
          postalCode: '',
          monFrom: '',
          monTo: '',
          tueFrom: '',
          tueTo: '',
          wedFrom: '',
          wedTo: '',
          thuFrom: '',
          thuTo: '',
          friFrom: '',
          friTo: '',
          satFrom: '',
          satTo: '',
          sunFrom: '',
          sunTo: '',
        },
        rules: {
          name: [
            {
              required: true,
              message: 'Please fill the input',
              trigger: 'blur',
            },
          ],
          storeUrl: [
            {
              required: true,
              validator: storeUrlInputValidator,
              trigger: 'blur',
            },
          ],
          orderFulfillmentTime: [
            {
              required: true,
              message: 'Please fill the input',
              trigger: 'blur',
            },
          ],
          refundsPolicy: [
            {
              required: true,
              message: 'Please fill the input',
              trigger: 'blur',
            },
          ],
          contactName: [
            {
              required: true,
              message: 'Please fill the input',
              trigger: 'blur',
            },
          ],
          contactPhone: [
            {
              required: true,
              message: 'Please fill the input',
              trigger: 'blur',
            },
          ],
          contactEmail: [
            { required: true, validator: emailInputValidator, trigger: 'blur' },
          ],
          street: [
            {
              required: true,
              message: 'Please fill the input',
              trigger: 'blur',
            },
          ],
          city: [
            {
              required: true,
              message: 'Please fill the input',
              trigger: 'blur',
            },
          ],
          province: [
            {
              required: true,
              message: 'Please fill the input',
              trigger: 'blur',
            },
          ],
          postalCode: [
            {
              required: true,
              message: 'Please fill the input',
              trigger: 'blur',
            },
          ],
        },
        unexpectedError: false,
        requestPending: false,
        storeAdded: false,
        brands: [],
        selectedBrand: '',
      }
    },

    components: {
      Tabs,
    },

    async created() {
      await this.loadBrands()
      this.selectedBrand = this.brands[0].brand_name
    },

    methods: {
      async loadBrands() {
        const resp = await storeAPI.getBrands()

        if (resp.ok) {
          const data = await resp.json()
          this.brands = data
        }
      },

      submitForm(formName) {
        const storeForm = this.$refs[formName]
        const storeFormModel = storeForm.model
        storeForm.validate(async (valid) => {
          if (valid) {
            this.requestPending = true

            const payload = {
              name: storeFormModel.name,
              storeUrl: storeFormModel.storeUrl,
              orderFulfillmentTime: storeFormModel.orderFulfillmentTime,
              contactName: storeFormModel.contactName,
              refundsPolicy: storeFormModel.refundsPolicy,
              contactPhone: storeFormModel.contactPhone,
              contactEmail: storeFormModel.contactEmail,
              brandName: this.selectedBrand,
              operating_hours: [],
              address: {
                address_line: storeFormModel.street,
                city: storeFormModel.city,
                province: storeFormModel.province,
                postal_code: storeFormModel.postalCode,
              },
            }
            if (storeFormModel.monFrom && storeFormModel.monTo) {
              payload.operating_hours.push({
                operating_hours_from: storeFormModel.monFrom,
                operating_hours_to: storeFormModel.monTo,
                day: 'Mon',
              })
            }
            if (storeFormModel.tueFrom && storeFormModel.tueTo) {
              payload.operating_hours.push({
                operating_hours_from: storeFormModel.tueFrom,
                operating_hours_to: storeFormModel.tueTo,
                day: 'Tue',
              })
            }
            if (storeFormModel.wedFrom && storeFormModel.wedTo) {
              payload.operating_hours.push({
                operating_hours_from: storeFormModel.wedFrom,
                operating_hours_to: storeFormModel.wedTo,
                day: 'Wed',
              })
            }
            if (storeFormModel.thuFrom && storeFormModel.thuTo) {
              payload.operating_hours.push({
                operating_hours_from: storeFormModel.thuFrom,
                operating_hours_to: storeFormModel.thuTo,
                day: 'Thu',
              })
            }
            if (storeFormModel.friFrom && storeFormModel.friTo) {
              payload.operating_hours.push({
                operating_hours_from: storeFormModel.friFrom,
                operating_hours_to: storeFormModel.friTo,
                day: 'Fri',
              })
            }
            if (storeFormModel.satFrom && storeFormModel.satTo) {
              payload.operating_hours.push({
                operating_hours_from: storeFormModel.satFrom,
                operating_hours_to: storeFormModel.satTo,
                day: 'Sat',
              })
            }
            if (storeFormModel.sunFrom && storeFormModel.sunTo) {
              payload.operating_hours.push({
                operating_hours_from: storeFormModel.sunFrom,
                operating_hours_to: storeFormModel.sunTo,
                day: 'Sun',
              })
            }

            const resp = await storeAPI.postStore(payload)

            if (resp.ok) {
              this.storeAdded = true
            } else {
              this.unexpectedError = true
            }

            this.requestPending = false
          }
        })
      },

      resetTheForm() {
        window.location.reload()
      },
    },
  }
</script>

<style lang="scss">
  .merchant-add-store {
    max-width: 750px;
    margin: 0 auto;

    &__form-inner {
      display: flex;

      > div {
        width: 50%;
        padding: 10px;
      }
    }

    &__submit {
      width: 100%;
    }

    .el-form-item {
      margin-bottom: 18px;
    }

    .el-form-item__label {
      line-height: 22px;
    }

    .merchant-add-store__hours {
      .el-form-item__label {
        width: 55px;
      }
    }
  }
</style>
